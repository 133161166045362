import useTooltipData from './hooks/use-tooltip-data';

const Tooltip = () => {
	const {
		label,
		showTooltip,
		className
	} = useTooltipData();

	return <>
		{ showTooltip && <div className={ className }>{ label }</div> }
	</>;
};

export default Tooltip;