import { useButtonData } from '../../../../utils';
import Icon from '../../../icon';

const AddToWishlistDropdownOption = ( { value, label } ) => {
	const {
		handleCloseDropdown,
		handleAddToWishlist,
	} = useButtonData();

	const handleOptionClick = event => {
		event.preventDefault();
		event.stopPropagation();
		handleCloseDropdown();
		handleAddToWishlist( value );
	}

	return <button className={ 'yith-wcwl-add-to-wishlist-dropdown__option' } onClick={ handleOptionClick }>
		<Icon className={ 'yith-wcwl-add-to-wishlist-dropdown__option__icon' } iconName={ 'plus' } width={ '14px' }/>
		<span className={ 'yith-wcwl-add-to-wishlist-dropdown__option__text' }>
		{ label }
		</span>
	</button>
}

export default AddToWishlistDropdownOption;