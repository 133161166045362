import { createWishlist } from '../../../features/wishlists/wishlists-actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectWishlistsCreation } from '../../../features/wishlists/wishlists-selectors';

const useCreateWishlist = () => {
	const dispatch = useDispatch();

	const { loading: isCreatingWishlist, error: wishlistCreationError } = useSelector( selectWishlistsCreation );

	const handleCreateWishlist = ( wishlistName, wishlistPrivacy, callback, additionalData = {} ) => {

		dispatch( createWishlist( { wishlist_name: wishlistName, wishlist_visibility: wishlistPrivacy, ...additionalData } ) ).then( () => {
			'function' === typeof callback && callback();
		} )
	}

	return {
		isCreatingWishlist,
		handleCreateWishlist,
		wishlistCreationError,
	};
}

export default useCreateWishlist;