import classNames from 'classnames';
import { ModalTitle } from '@lapilli-ui/components';
import Icon from '../../../icon';

const PluginModalTitle = ( { title, icon, children, classes, className } ) => {
	const titleClasses = classNames(
		'yith-wcwl-modal__title',
		className,
		classes?.title
	)
	const iconClasses = classNames(
		'yith-wcwl-modal__icon',
		classes?.icon
	)
	const titleTextClasses = classNames(
		'yith-wcwl-modal__title-text',
		classes?.titleText
	)
	return <ModalTitle className={ titleClasses }>
		{ icon && <Icon className={ iconClasses } iconName={ icon } width={ '80px' }/> }
		{ title && <div className={ titleTextClasses }>{ title }</div> }
		{ children }
	</ModalTitle>
}

export default PluginModalTitle;