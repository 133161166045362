/* globals yithWcwlAddToWishlist */

import { __ } from '@wordpress/i18n';

import { ModalContent, ModalActions, Select, Button } from '@lapilli-ui/components';
import { useButtonData } from '../../../../utils';
import { useChooseAddToWishlistModalData } from './hooks/use-choose-add-to-wishlist-modal-data';

const ChooseWishlistModalContent = () => {
	const {
		modalWishlistId,
		modalWishlistSelectError
	} = useButtonData();

	const {
		buttonClasses,
		handleWishlistSelectChange,
		handleAddToWishlist,
		chooseOptions
	} = useChooseAddToWishlistModalData();

	return <>
		<ModalContent className={ 'yith-wcwl-modal__content yith-wcwl-add-to-wishlist-modal__content' }>
			<Select
				options={ chooseOptions }
				placeholder={ __( 'Choose a wishlist' ) }
				error={ modalWishlistSelectError }
				onChange={ handleWishlistSelectChange }
				onClear={ handleWishlistSelectChange }
				value={ modalWishlistId }
			/>
		</ModalContent>
		<ModalActions className={ 'yith-wcwl-modal__actions yith-wcwl-add-to-wishlist-modal__actions' }>
			<Button className={ buttonClasses } onClick={ handleAddToWishlist } size={ 'lg' }>{ __( 'Add to wishlist', 'yith-woocommerce-wishlist' ) }</Button>
		</ModalActions>
	</>;
}

export default ChooseWishlistModalContent;