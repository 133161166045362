import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useButtonDropdownData } from '../../../../../utils/button-data-context';

export default () => {
	const {
		buttonRef,
		availableLists,
		isDropdownOpen,
		handleCloseDropdown,
		wishlists,
	} = useButtonDropdownData();

	const [ limit, setLimit ] = useState( 5 );
	const [ horizontalPosition, setHorizontalPosition ] = useState( 'right' );
	const [ verticalPosition, setVerticalPosition ] = useState( 'bottom' );

	const handleIncreaseLimitClick = event => {
		event.preventDefault();
		event.stopPropagation();
		setLimit( limit + 5 );
	};
	const dropdownRef = useRef( null );
	const handleClickOutside = ( event ) => {
		if ( dropdownRef.current && ! dropdownRef.current.contains( event.target ) ) {
			handleCloseDropdown();
		}
	};
	const handleDropdownPosition = () => {
		if ( buttonRef?.current ) {
			const { x, y } = buttonRef.current.getBoundingClientRect();
			const { innerWidth, innerHeight } = window;
			setVerticalPosition( y > (innerHeight / 2) ? 'top' : 'bottom' )
			setHorizontalPosition( x > (innerWidth / 2) ? 'left' : 'right' );
		}
	}

	useEffect( () => {
		setLimit(5);
		if ( isDropdownOpen ) {
			document.addEventListener( 'mousedown', handleClickOutside );
		} else {
			document.removeEventListener( 'mousedown', handleClickOutside );
		}

		return () => {
			document.removeEventListener( 'mousedown', handleClickOutside );
		};
	}, [ isDropdownOpen ] );

	useEffect( () => {
		handleDropdownPosition();

		window.addEventListener( 'scroll', handleDropdownPosition );

		return () => window.removeEventListener( 'scroll', handleDropdownPosition );
	}, [] );

	const className = classnames(
		'yith-wcwl-add-to-wishlist-dropdown',
		`yith-wcwl-add-to-wishlist-dropdown--${ verticalPosition }-${ horizontalPosition }`
	)

	return {
		limit,
		wishlists,
		className,
		dropdownRef,
		isDropdownOpen,
		availableLists,
		verticalPosition,
		horizontalPosition,
		handleIncreaseLimitClick,
	}
}