/* globals yithWcwlAddToWishlist */

import { useButtonData } from '../../../../../utils';
import { __ } from '@wordpress/i18n';
import { ModalContent, ModalActions, Button } from '@lapilli-ui/components';
import Modal from '../../modal';
import classnames from 'classnames';

export const ModalFeedbackAdded = ( { className, ...props } ) => {
	const {
		wishlistURL,
		showFeedback,
		setShowFeedback,
		feedbackMessage,
	} = useButtonData();

	const browseButtonClasses = classnames(
		yithWcwlAddToWishlist.buttonClasses,
		'yith-wcwl-modal__primary-button',
	)
	const closeButtonClasses = classnames(
		yithWcwlAddToWishlist.buttonClasses,
		'yith-wcwl-modal__secondary-button',
		'yith-wcwl-feedback-modal__footer__close-button',
	)

	return <Modal classes={ { modal: className } } icon={ 'heart-check-light' } open={ showFeedback } onClose={ () => setShowFeedback( false ) } { ...props }>
		<ModalContent className={ 'yith-wcwl-modal__content yith-wcwl-feedback-modal__content' }>
			<div className={ 'yith-wcwl-feedback-modal__message' }>
				{ feedbackMessage }
			</div>
		</ModalContent>
		<ModalActions className={ 'yith-wcwl-modal__actions yith-wcwl-feedback-modal__footer' }>
			<a href={ wishlistURL } className={ 'yith-wcwl-feedback-modal__browse-button' }>
				<Button className={ browseButtonClasses } size={ 'lg' }>
					{ yithWcwlAddToWishlist.globalOptions.browse_label }
				</Button>
			</a>
			<Button onClick={ () => setShowFeedback( false ) } className={ closeButtonClasses } size={ 'lg' }>
				{ __( 'Close', 'yith-woocommerce-wishlist' ) }
			</Button>
		</ModalActions>
	</Modal>
}
