/* globals yithWcwlAddToWishlist */

import { __ } from '@wordpress/i18n';
import { ModalContent, Input, RadioGroup, ModalActions, Button } from '@lapilli-ui/components';
import { getPrivacyOptions, useButtonData } from '../../../../utils';
import { useCreateAddToWishlistModalData } from './hooks/use-create-add-to-wishlist-modal-data';

export const CreateNewWishlistModalContent = () => {
	const {
		modalWishlistName,
		modalWishlistNameError,
		modalWishlistPrivacy,
	} = useButtonData();
	const {
		handleWishlistNameInputChange,
		handleCreateAndAddToWishlist,
		handlePrivacyChange,
		buttonLabel,
		buttonClasses,
	} = useCreateAddToWishlistModalData();

	return <>
		<ModalContent className={ 'yith-wcwl-modal__content yith-wcwl-add-to-wishlist-modal__content' }>
			<Input
				placeholder={ __( 'Wishlist name', 'yith-woocommerce-wishlist' ) }
				value={ modalWishlistName }
				onChange={ handleWishlistNameInputChange }
				error={ modalWishlistNameError }
				fullWidth={ true }
			/>
			<RadioGroup
				options={ getPrivacyOptions( true ) }
				value={ modalWishlistPrivacy }
				onChange={ handlePrivacyChange }
			/>
		</ModalContent>
		<ModalActions className={ 'yith-wcwl-modal__actions yith-wcwl-add-to-wishlist-modal__actions' }>
			<Button className={ buttonClasses } onClick={ handleCreateAndAddToWishlist } size={ 'lg' }>{ buttonLabel }</Button>
		</ModalActions>
	</>
}

export default CreateNewWishlistModalContent;