/* globals yithWcwlAddToWishlist */

import { useButtonData } from '../../../../../utils';
import { __ } from '@wordpress/i18n';
import { ModalContent, ModalActions, Button } from '@lapilli-ui/components';
import Modal from '../../modal';
import classnames from 'classnames';

export const ModalFeedbackRemoved = ( { className, ...props } ) => {
	const {
		showFeedback,
		setShowFeedback,
		feedbackMessage,
	} = useButtonData();

	const closeButtonClasses = classnames(
		yithWcwlAddToWishlist.buttonClasses,
		'yith-wcwl-modal__secondary-button',
		'yith-wcwl-feedback-modal__footer__close-button',
	)

	return <Modal className={ className } icon={ 'heart-remove-light' } open={ showFeedback } onClose={ () => setShowFeedback( false ) } { ...props }>
		<ModalContent className={ 'yith-wcwl-modal__content yith-wcwl-feedback-modal__content' }>
			<div className={ 'yith-wcwl-feedback-modal__message' }>
				{ feedbackMessage }
			</div>
		</ModalContent>
		<ModalActions className={ 'yith-wcwl-modal__actions yith-wcwl-feedback-modal__footer' }>
			<Button onClick={ () => setShowFeedback( false ) } className={ closeButtonClasses } size={ 'lg' }>{ __( 'Close', 'yith-woocommerce-wishlist' ) }</Button>
		</ModalActions>
	</Modal>
}
