import WishlistCounter from './wishlist-counter'
import WishlistCounterSingle from './wishlist-counter-single'
import { useButtonData } from '../../../../utils';

export default () => {
	const { richAttributes: { is_single } } = useButtonData();

	return <>
		{ is_single ? <WishlistCounterSingle/> : <WishlistCounter/> }
	</>;
}