import Icon from '../../../icon';
import { __ } from '@wordpress/i18n';
import classNames from 'classnames';
import Modal from '../modal'
import ModalTitle from '../modal/modal-title'
import { useButtonData, useButtonAttributes, } from '../../../../utils';
import CreateNewWishlistModalContent from './create-new-wishlist-modal-content';
import ChooseWishlistModalContent from './choose-wishlist-modal-content';

const AddToWishlistModal = ( { className } ) => {
	className = classNames(
		className,
		'yith-wcwl-modal',
		'yith-wcwl-add-to-wishlist-modal'
	);

	const {
		modalType,
		isModalOpened,
		setIsModalOpened,
		setModalType,
		modalIcon,
	} = useButtonData();

	const handleClose = () => {
		setModalType( 'choose' )
		setIsModalOpened( false )
	};

	return <Modal open={ isModalOpened } onClose={ handleClose } disableAutoFocus={ true } className={ className }>
		<ModalTitle className={ 'yith-wcwl-modal__title yith-wcwl-add-to-wishlist-modal__title' } icon={ 'default' === modalIcon ? 'heart-light' : modalIcon }>
			<div className={ 'yith-wcwl-add-to-wishlist-modal__type_selection yith-wcwl-add-to-wishlist-modal__type_selection--' + modalType }>
				<button
					className={ classNames( [ 'yith-wcwl-add-to-wishlist-modal__type_option', { 'yith-wcwl-add-to-wishlist-modal__type_option--selected': 'choose' === modalType } ] ) }
					role={ 'button' }
					tabIndex={ 'choose' === modalType ? -1 : 1 }
					onClick={ () => setModalType( 'choose' ) }
				>
					{ __( 'Choose wishlist', 'yith-woocommerce-wishlist' ) }
				</button>
				<button
					className={ classNames( [ 'yith-wcwl-add-to-wishlist-modal__type_option', { 'yith-wcwl-add-to-wishlist-modal__type_option--selected': 'create' === modalType } ] ) }
					role={ 'button' }
					tabIndex={ 'create' === modalType ? -1 : 1 }
					onClick={ () => setModalType( 'create' ) }
				>
					{ __( 'Create a new wishlist', 'yith-woocommerce-wishlist' ) }
				</button>
			</div>
		</ModalTitle>
		{ 'choose' === modalType && <ChooseWishlistModalContent/> }
		{ 'create' === modalType && <CreateNewWishlistModalContent/> }
	</Modal>
}

export default AddToWishlistModal;