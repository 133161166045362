import { useState } from 'react';

const useMoveToAnotherWishlistModal = ( context ) => {
	const [ moveModalWishlistId, setMoveModalWishlistId ] = useState( 0 );
	const [ moveModalWishlistPrivacy, setMoveModalWishlistPrivacy ] = useState( 0 );
	const [ moveModalWishlistName, setMoveModalWishlistName ] = useState( '' );
	const [ isMoveModalOpened, setIsMoveModalOpened ] = useState( false );
	const [ moveModalType, setMoveModalType ] = useState( 'choose' );
	const [ moveModalWishlistSelectError, setMoveModalWishlistSelectError ] = useState( false );
	const [ moveModalWishlistNameError, setMoveModalWishlistNameError ] = useState( false );

	const openMoveToAnotherWishlistModal = () => {
		setMoveModalWishlistId( 0 );
		setMoveModalWishlistPrivacy( 0 );
		setMoveModalWishlistName( '' );
		setMoveModalType( 'choose' );
		setMoveModalWishlistNameError( false );
		setMoveModalWishlistSelectError( false );

		setIsMoveModalOpened( true );
	}

	return {
		moveModalWishlistId,
		moveModalWishlistPrivacy,
		moveModalWishlistName,
		moveModalWishlistSelectError,
		moveModalWishlistNameError,
		moveModalType,
		isMoveModalOpened,

		setMoveModalType,
		setIsMoveModalOpened,
		setMoveModalWishlistId,
		setMoveModalWishlistName,
		setMoveModalWishlistPrivacy,
		setMoveModalWishlistNameError,
		setMoveModalWishlistSelectError,

		openMoveToAnotherWishlistModal,
	};
}

export default useMoveToAnotherWishlistModal;