import AddToWishlistDropdownOption from './add-to-wishlist-dropdown-option';
import useAddToWishlistDropdownData from './hooks/use-add-to-wishlist-dropdown-data';
import { __ } from '@wordpress/i18n';

const AddToWishlistDropdown = () => {
	const {
		limit,
		wishlists,
		className,
		dropdownRef,
		availableLists,
		isDropdownOpen,
		handleIncreaseLimitClick,
	} = useAddToWishlistDropdownData();

	return <>
		{
			isDropdownOpen && !! availableLists.length &&
			<div className={ className } ref={ dropdownRef }>
				{
					availableLists.slice( 0, limit ).map(
						listId => <AddToWishlistDropdownOption value={ listId } label={ wishlists[ listId ]?.name } key={ listId }/>
					)
				}
				{
					availableLists.length > limit &&
					<button className={ 'yith-wcwl-add-to-wishlist-dropdown__view-more' } onClick={ handleIncreaseLimitClick }>
						{ __( 'View 5 more', 'yith-woocommerce-wishlist' ) }
					</button>
				}
			</div>

		}
	</>
}

export default AddToWishlistDropdown