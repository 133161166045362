import { useState } from 'react';

const useAddToWishlistModal = ( context ) => {
	const {
		richAttributes: {
			modal_icon_type,
			modal_icon,
			modal_custom_icon
		},
		add_icon,
		added_icon
	} = context;
	const [ modalWishlistName, setModalWishlistName ] = useState( '' )
	const [ modalWishlistPrivacy, setModalWishlistPrivacy ] = useState( 0 )
	const [ modalWishlistId, setModalWishlistId ] = useState( -1 )
	const [ isModalOpened, setIsModalOpened ] = useState( false );
	const [ modalWishlistSelectError, setModalWishlistSelectError ] = useState( false );
	const [ modalType, setModalType ] = useState( 'choose' );
	const [ modalWishlistNameError, setModalWishlistNameError ] = useState( false );

	const getModalIcon = () => {
		if ( 'default' === modal_icon_type ) {
			return 'default';
		}
		if ( 'custom' === modal_icon_type ) {
			return modal_custom_icon;
		}

		if ( 'same-to-add' === modal_icon_type ) {
			return add_icon;
		}

		if ( 'same-to-added' === modal_icon_type ) {
			return added_icon;
		}

		return modal_icon;
	};

	const openAddToWishlistModal = () => {
		setModalWishlistName( '' );
		setModalWishlistId( 0 );
		setModalWishlistPrivacy( 0 );
		setModalWishlistSelectError( false );
		setModalWishlistNameError( false );

		setIsModalOpened( true );
	}

	return {
		modalType,
		modalIcon: getModalIcon(),
		isModalOpened,
		modalWishlistId,
		modalWishlistName,
		modalWishlistNameError,
		modalWishlistSelectError,
		modalWishlistPrivacy,

		setModalType,
		setIsModalOpened,
		setModalWishlistNameError,
		setModalWishlistSelectError,
		setModalWishlistName,
		setModalWishlistPrivacy,
		setModalWishlistId,

		openAddToWishlistModal,
	};
}

export default useAddToWishlistModal;