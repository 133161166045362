import { useButtonData } from '../../../../utils';

const WishlistCounter = () => {
	const {
		richAttributes: {
			count: attributeCount,
			show_count_in_loop,
		},
		count: productDataCount
	} = useButtonData();

	const count = undefined !== productDataCount ? productDataCount : attributeCount;
	return <>
		{ 'yes' === show_count_in_loop && !! count && <span className="yith-wcwl-add-to-wishlist__counter">{ count }</span> }
	</>;
}

export default WishlistCounter;