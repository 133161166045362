import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useButtonTooltipData } from '../../../../../utils/button-data-context';

const useTooltipData = () => {
	const [ position, setPosition ] = useState( 'bottom' );
	const [ showTooltip, setShowTooltip ] = useState( false );
	const {
		hasDataLoaded,
		add_label,
		remove_label,
		browse_label,
		move_label,

		isAdded,
		behaviour,
		buttonRef,
		isDropdownOpen,
		tooltipEnabled,
	} = useButtonTooltipData();

	const getLabel = () => {
		if ( isAdded ) {
			switch ( behaviour ) {
				case'view':
					return browse_label;
				case 'remove':
					return remove_label;
				case 'modal':
					return move_label;
			}
		}
		return add_label;
	}

	const handlePosition = () => {
		if ( buttonRef?.current && isDropdownOpen ) {
			const { y } = buttonRef.current.getBoundingClientRect();
			const { innerHeight } = window;
			if ( y <= (innerHeight / 2) && isDropdownOpen ) {
				setPosition( 'top' )
				return;
			}
		}
		setPosition( 'bottom' )
	}

	useEffect( () => {
		handlePosition();
		if ( isDropdownOpen ) {
			window.addEventListener( 'scroll', handlePosition );

			return () => window.removeEventListener( 'scroll', handlePosition );
		}
	}, [ isDropdownOpen ] );

	useEffect( () => {
		const handleTooltipVisibility = ( event ) => {
			const isMouseOver = buttonRef.current && buttonRef.current.contains( event.target )
			setShowTooltip( isMouseOver );
		}
		const show = () => setShowTooltip( true );
		const hide = () => setShowTooltip( false );

		if ( tooltipEnabled ) {
			window.addEventListener( 'mousemove', handleTooltipVisibility )
			buttonRef.current?.addEventListener( 'focus', show )
			buttonRef.current?.addEventListener( 'blur', hide )
			return () => {
				window.removeEventListener( 'mousemove', handleTooltipVisibility )
				buttonRef.current?.removeEventListener( 'focus', show )
				buttonRef.current?.removeEventListener( 'blur', hide )
			};
		}
	}, [] );

	const className = classnames(
		'yith-wcwl-add-to-wishlist-tooltip',
		`yith-wcwl-add-to-wishlist-tooltip--${ position }`,
	);

	return {
		position,
		setPosition,
		label: getLabel(),
		showTooltip: showTooltip && hasDataLoaded,
		className,
	}
};

export default useTooltipData;