/* globals yithWcwlAddToWishlist */

import { useButtonData } from '../../../../utils';
import { ModalFeedbackRemoved } from './variants/modal-feedback-removed';
import { ModalFeedbackAdded } from './variants/modal-feedback-added';

import classnames from 'classnames';

const ModalFeedback = () => {
	const {
		richAttributes: {
			modal_close_behaviour
		},
		showFeedback,
		feedbackType,
		feedbackFadeOut,
		feedbackAnimation,
	} = useButtonData();

	const Modal = props => ([ 'product_removed', 'error' ].includes( feedbackType ) ? <ModalFeedbackRemoved { ...props }/> : <ModalFeedbackAdded { ...props }/>);

	const className = classnames(
		'yith-wcwl-feedback-modal',
		{
			'yith-wcwl-fade-out': feedbackFadeOut,
			'yith-wcwl-feedback-modal--no-close': 'open' === modal_close_behaviour,
			'yith-wcwl-feedback-modal--no-animation': ! feedbackAnimation,
		}
	)

	return <>
		{
			showFeedback && <Modal className={ className }/>
		}
	</>
}

export default ModalFeedback;
