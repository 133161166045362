/* globals yithWcwlAddToWishlist */

import classnames from 'classnames';
import { getProductFreeListsOptions, useButtonData } from '../../../../../utils';
import { useEffect } from 'react';

export const useChooseAddToWishlistModalData = () => {
	const {
		setIsModalOpened,
		handleAddToWishlist: addToWishlist,
		modalWishlistSelectError,
		modalWishlistId,
		setModalWishlistId,
		setModalWishlistSelectError,
		isLoading,
		setModalType,
		setFeedbackAnimation,
	} = useButtonData();

	const chooseOptions = getProductFreeListsOptions( true );

	useEffect( () => {
		setModalWishlistId( chooseOptions[ 0 ]?.value );
	}, [] );

	const handleAddToWishlist = () => {
		if ( modalWishlistId ) {
			addToWishlist( modalWishlistId, () => {
				setIsModalOpened( false );
				setFeedbackAnimation( false );
			} );
		} else if ( ! modalWishlistSelectError ) {
			setModalWishlistSelectError( true );
		}
	}
	const handleWishlistSelectChange = optionValue => {
		if ( 'create' === optionValue ) {
			setModalType( 'create' );
			return;
		}

		setModalWishlistId( isNaN( optionValue ) ? optionValue : Number( optionValue ) );
		setModalWishlistSelectError( false );
	};
	const handleClose = () => setIsModalOpened( false );

	const buttonClasses = classnames(
		yithWcwlAddToWishlist.buttonClasses,
		'yith-wcwl-modal__primary-button',
		'yith-wcwl-add-to-wishlist-modal__add-to-wishlist-button',
		{
			'yith-wcwl-modal__primary-button--loading': isLoading
		}
	);

	return {
		buttonClasses,
		handleClose,
		handleWishlistSelectChange,
		handleAddToWishlist,
		chooseOptions
	}
}