import classnames from 'classnames';
import { getProductFreeListsOptions, useButtonData } from '../../../../../utils';
import { useWishlistData } from '../../../../../hooks/use-wishlist-data';
import { useEffect } from 'react';

export const useMoveToAnotherWishlistModalData = () => {

	const className = classnames(
		'yith-wcwl-modal',
		'yith-wcwl-move-to-another-wishlist-modal',
	);

	const {
		lists,
		isLoading,
		setMoveModalWishlistName,
		moveModalWishlistId,
		moveModalWishlistName,
		setMoveModalWishlistPrivacy,
		moveModalType,
		setMoveModalWishlistId,
		moveModalWishlistPrivacy,
		setMoveModalType,
		setIsMoveModalOpened,
		setFeedbackAnimation,
		setMoveModalWishlistNameError,
		setMoveModalWishlistSelectError,
		handleRemoveFromWishlist: removeFromWishlist,
		handleMoveToAnotherWishlist: moveToAnotherWishlist,
	} = useButtonData();

	const chooseOptions = getProductFreeListsOptions( true );

	useEffect( () => {
		const firstOption = chooseOptions[ 0 ]?.value;
		setMoveModalWishlistId( firstOption );
		if ( 'create' === firstOption ) {
			setMoveModalType( 'create' );
		}
	}, [] );

	const { name: originWishlistName } = useWishlistData( lists[ 0 ] ) || {};

	const handleClose = () => setIsMoveModalOpened( false );

	const handleMoveToAnotherWishlist = () => {
		setMoveModalWishlistNameError( ! moveModalWishlistName );

		const data = {
			destination_wishlist: moveModalWishlistId,
			origin_wishlist: lists[ 0 ],
		}

		if ( 'create' === moveModalType ) {
			if ( ! moveModalWishlistName ) {
				return
			}

			data.destination_wishlist = 'new';
			data.wishlist_name = moveModalWishlistName;
			data.wishlist_visibility = moveModalWishlistPrivacy;
		} else if ( ! moveModalWishlistId ) {
			setMoveModalWishlistSelectError( true );
			return;
		}

		moveToAnotherWishlist( data, () => {
			setIsMoveModalOpened( false )
			setFeedbackAnimation( false )
		} );
	}

	const handleWishlistSelectChange = wishlistId => {
		if ( 'create' === wishlistId ) {
			setMoveModalType( 'create' );
		} else {
			setMoveModalType( 'choose' );
			setMoveModalWishlistId( wishlistId ? Number( wishlistId ) : undefined );
		}
		setMoveModalWishlistSelectError( false );
	};

	const handleRemoveFromWishlist = () => ! isLoading && removeFromWishlist( lists[ 0 ], () => {
		setIsMoveModalOpened( false );
		setFeedbackAnimation( false );
	} );

	const handleWishlistNameInputChange = ( e, wishlistName ) => {
		setMoveModalWishlistName( wishlistName );
		setMoveModalWishlistNameError( ! wishlistName );
	};

	const handlePrivacyChange = ( e, value ) => {
		setMoveModalWishlistPrivacy( Number( value ) );
	}

	return {
		className,
		handleClose,
		chooseOptions,
		originWishlistName,
		handlePrivacyChange,
		handleRemoveFromWishlist,
		handleWishlistSelectChange,
		handleMoveToAnotherWishlist,
		handleWishlistNameInputChange,
	};
}