/* globals yithWcwlAddToWishlist */

import WishlistCounter from '../sub-components/wishlist-counter'
import ModalFeedback from '../sub-components/modal-feedback'
import AddToWishlistModal from '../sub-components/add-to-wishlist-modal';
import PillowButton from '../sub-components/add-to-wishlist-button/variants/add-to-wishlst-pillow-button'
import Tooltip from '../sub-components/tooltip'
import MoveToAnotherWishlistModal from '../sub-components/move-to-another-wishlist-modal';
import AddToWishlistDropdown from '../sub-components/add-to-wishlist-dropdown';
import { useButtonData } from '../../../utils';

const extensions = {
	afterAddToWishlistButton: () => {
		const {
			is_single,
			behaviour,
			isModalOpened,
			isMoveModalOpened,
		} = useButtonData();

		return <>
			{ is_single && <WishlistCounter/> }
			{ isModalOpened && <AddToWishlistModal/> }
			{ 'modal' === behaviour && isMoveModalOpened && <MoveToAnotherWishlistModal/> }
		</>
	},
	addToWishlistButtonChildren: () => {
		const {
			is_single,
		} = useButtonData();
		return <>
			{ ! is_single && <WishlistCounter/> }
			<AddToWishlistDropdown/>
			<Tooltip/>
		</>
	},
	buttonStylePillow: ( props ) => <PillowButton { ...props }/>,
}

if ( 'modal' === yithWcwlAddToWishlist.globalOptions?.feedback_type ) {
	extensions.renderFeedback = props => <ModalFeedback { ...props }/>;
}

export default extensions;