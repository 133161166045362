import { useDispatch, useSelector } from 'react-redux';
import { selectButtonData } from '../../../features/buttons/buttons-selectors';
import { closeAllButtonDropdowns, closeButtonDropdown, openButtonDropdown } from '../../../features/buttons/buttons-actions';

const useDropdown = ( { buttonId, availableLists } ) => {
	const dispatch = useDispatch();

	const { isDropdownOpen } = useSelector( state => selectButtonData( state, buttonId ) ) || {};

	const handleToggleDropdown = () => {
		if ( isDropdownOpen ) {
			dispatch( closeButtonDropdown( buttonId ) );
		} else {
			availableLists?.length && dispatch( openButtonDropdown( buttonId ) );
		}
	};
	const handleCloseDropdown = () => {
		dispatch( closeAllButtonDropdowns() );
	}

	return {
		isDropdownOpen,

		handleCloseDropdown,
		handleToggleDropdown,
	}
}

export default useDropdown;