/* globals yithWcwlAddToWishlist */

import { Modal } from '@lapilli-ui/components';
import PluginModalTitle from './modal-title'
import classNames from 'classnames';

export const PluginModal = ( { classes, className, title, icon, children, ...props } ) => {

	const modalClasses = classNames(
		'yith-wcwl-modal',
		className,
		classes?.modal
	);

	return <Modal className={ modalClasses } { ...props } disableRestoreFocus={ true }>
		{ (icon || title) && <PluginModalTitle { ...{ classes, icon, title } }/> }
		{ children }
	</Modal>
}

export default PluginModal;
