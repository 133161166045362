import { useButtonData } from '../../../../utils';
import { _n, __ } from '@wordpress/i18n';

const WishlistCounter = () => {
	const {
		richAttributes: {
			show_count,
			count: attributeCount,
		},
		isAdded,
		count: productDataCount,
	} = useButtonData();

	const count = undefined !== productDataCount ? productDataCount : attributeCount;

	return <>
		{
			'yes' === show_count && !! count &&
			<span className="yith-wcwl-add-to-wishlist__counter">
				{
					isAdded && 1 === count ?
						__( 'You\'re the first to add this item to a wishlist!', 'yith-woocommerce-wishlist' ) :
						_n( '{{users_count}} user has this item in their wishlist', '{{users_count}} users have this item in their wishlists', count, 'yith-woocommerce-wishlist' ).replace( '{{users_count}}', count )
				}
			</span>
		}
	</>;
}

export default WishlistCounter;
