/* globals yithWcwlAddToWishlist  */

import { useButtonData } from '../../../../../utils';
import classNames from 'classnames';
import { __, _x } from '@wordpress/i18n';

export const useCreateAddToWishlistModalData = () => {
	const {
		modalWishlistName,
		setModalWishlistName,
		setModalWishlistNameError,
		modalWishlistPrivacy,
		setModalWishlistPrivacy,
		isCreatingWishlist,
		handleCreateAndAddToWishlist: createAndAddToWishlist,
		isLoading,
		setIsMoveModalOpened,
		setFeedbackAnimation,
	} = useButtonData();

	const buttonClasses = classNames(
		yithWcwlAddToWishlist.buttonClasses,
		'yith-wcwl-add-to-wishlist-modal__create-button',
		'yith-wcwl-modal__primary-button',
		{
			'yith-wcwl-modal__primary-button--loading': isCreatingWishlist || isLoading,
		}
	)

	const handleWishlistNameInputChange = ( e, wishlistName ) => {
		setModalWishlistName( wishlistName );
		setModalWishlistNameError( ! wishlistName );
	};
	const handleCreateAndAddToWishlist = () => {
		setModalWishlistNameError( ! modalWishlistName );

		if ( modalWishlistName ) {
			createAndAddToWishlist(
				{ wishlist_name: modalWishlistName, wishlist_visibility: modalWishlistPrivacy },
				() => {
					setIsMoveModalOpened( false )
					setFeedbackAnimation( false );
				} );
		}
	}
	const handlePrivacyChange = ( e, value ) => {
		setModalWishlistPrivacy( Number( value ) );
	}

	const getButtonLabel = () => {
		if ( isCreatingWishlist ) {
			return _x( 'Creating the wishlist', '[Modal] The add to wishlist modal button label while creating a new wishlist','yith-woocommerce-wishlist' );
		}

		return isLoading ? _x( 'Adding to the wishlist', '[Modal] The add to wishlist modal button label while adding a product to the wishlist', 'yith-woocommerce-wishlist' ) : __( 'Create wishlist', 'yith-woocommerce-wishlist' );
	};

	return {
		buttonClasses,
		buttonLabel: getButtonLabel(),

		handlePrivacyChange,
		handleCreateAndAddToWishlist,
		handleWishlistNameInputChange,
	}
}